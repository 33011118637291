import { css } from 'styled-components'

export const NavStyles = css`
  :root {
    --nav-background: var(--white);
    --nav-shadow: var(--shadow);
    --topbar-height: 50px;
    --topbar-background: var(--nav-background);
    --topbar-shadow: var(--nav-shadow);
    --topbar-color: var(--gray-300);
    --aside-background: var(--white);
    --aside-shadow: var(--nav-shadow);
    --aside-item-background: var(--white);
    --aside-item-color: var(--gray-300);
    --aside-item-hover-background: var(--gray-30);
    --aside-item-hover-color: var(--gray-300);
    --aside-item-active-background: var(--gray-300);
    --aside-item-active-color: var(--gray-20);
    --aside-on-closed-width: 50px;
    --aside-on-open-width: 320px;
    --aside-on-open-width-mobile: 90vw;
  }
`
