import styled, { css } from 'styled-components'

import { IContainerProps } from '.'

import background from '../../assets/img/Background-waves.png'

export const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-image: url(${background});
  background-size: 100% 100%;
`

export const Wrapper = styled.div<IContainerProps>`
  ${({ justifyContent }) => css`
    display: flex;
    flex-direction: column;
    justify-content: ${justifyContent};
    align-items: center;

    width: 100%;
    min-height: 100vh;

    position: relative;

    & > header {
      justify-content: ${justifyContent};
    }

    & > main {
      width: 100%;
    }

    ${justifyContent === 'center' &&
    css`
      padding-top: 1.9rem;

      & > header {
        padding-bottom: 2rem;
      }
    `}

    ${justifyContent === 'flex-start' &&
    css`
      & > main {
        width: 100%;
        flex: 1;
      }
    `}

    & > header {
      width: 100%;
      padding: 2rem;

      display: flex;

      @media ${(props) => props.theme.breakpoints.mobile} {
        justify-content: center;
      }
    }

    & > footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 0.75rem;
      padding: 2rem;

      @media ${(props) => props.theme.breakpoints.mobile} {
        flex-direction: column;
        align-items: center;
        font-size: 1rem;
        text-align: center;
      }
    }
  `}
`

export const LogoWrapper = styled.div`
  width: 100%;
  max-width: 15.4rem;

  display: flex;
  justify-content: center;
`

export const LinksWrapper = styled.div`
  & > a {
    margin-left: 1.5rem;

    @media ${(props) => props.theme.breakpoints.mobile} {
      margin-left: 0;
      display: block;
      margin-top: 0.8rem;
    }
  }
`
