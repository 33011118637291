import { css } from 'styled-components'

export const FormStyles = css`
  :root {
    --input-color: var(--black);
    --input-border: var(--gray-100);
    --input-hover-border: var(--gray-900);
    --input-disabled-background: var(--gray-30);
    --input-disabled-border: var(--gray-700);
    --input-focus-shadow: 0 0 0 2px var(--primary-500);
    --input-error: var(--danger);
    --input-success: var(--gray-100);
    --input-verifying: var(--blue-300);
    --select-multivalue-background: var(--gray-30);
    --select-option-selected: var(--gray-40);
    --select-option-color: var(--black);
    --select-option-hover-background: var(--gray-30);
    --select-option-hover-color: var(--black);
    --select-option-disabled: var(--gray-50);
    --checkbox-checked-background: var(--gray-600);
  }
`
