import styled from 'styled-components'

import { Item as UIItem } from 'components/Form'

export const NavWrapper = styled.div`
  & button {
    @media ${(props) => props.theme.breakpoints.mobile} {
      display: none;
    }
  }
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 23.5rem;
  margin: 0 auto;

  padding: 0 2rem;
`

export const Heading = styled.h1`
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 0;
`

export const Item = styled(UIItem)`
  margin-top: 1.5rem;
  padding: 0;
`

export const LinkWrapper = styled.div`
  margin-top: 1.125rem;
  text-align: right;
  font-size: 0.875rem;
`

export const ButtonWrapper = styled.div`
  margin-top: 2rem;

  & button {
    margin-left: auto;
    margin-right: auto;
    font-weight: 500;
  }

  & span {
    display: none;
    text-align: center;

    @media ${(props) => props.theme.breakpoints.mobile} {
      display: block;
    }
  }
`
