import axios from 'axios'

export const authService = axios.create({
  baseURL: process.env.REACT_APP_AUTH_SERVICE
})

authService.interceptors.response.use(
  function (response) {
    return Promise.resolve(response.data.data)
  },
  function ({ response }) {
    const { data } = response

    return Promise.reject(data)
  }
)
