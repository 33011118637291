import { useCallback } from 'react'

import { intuixApi } from 'services/intuixApi'

type FindCustomerByIdPublicDTO = {
  customerId: string
}

type FindCustomerByIdPublicResponse = {
  id: string
  name: string
  systemAccessId: string
  products: string
}

export const useRequestCustomerRelationshipModule = () => {
  const findCustomerByIdPublic = useCallback(
    (
      dto: FindCustomerByIdPublicDTO
    ): Promise<FindCustomerByIdPublicResponse> => {
      return intuixApi.get(
        `/customers-relationship/customers/${dto.customerId}/public`
      )
    },
    []
  )

  return {
    findCustomerByIdPublic
  }
}
